import { all } from "redux-saga/effects";
import Auth from "./Auth";
import FloorsApi from "./FloorsApi";
import TableTypeApi from "./TableTypeApi";
import TableApi from "./TableApi";
import RestaurantStatsApi from "./RestaurantStatsApi";
import RestaurantApi from "./RestaurantApi";
import ReservationApi from "./ReservationApi";
import RestaurantTypeApi from "./RestaurantTypeApi";
import CousinsApi from "./CuisineApi";
import FeaturesApi from "./FeaturesApi";
import TagsApi from "./TagsApi";
import RestaurantImageApi from "./RestaurantImageApi";
import OffersApi from "./OffersApi";
import MenuImageApi from "./MenuImageApi";
import AboutApi from "./AboutApi";
import LocationApi from "./LocationApi";
import CustomerApi from './CustomerApi';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    FloorsApi(),
    TableTypeApi(),
    TableApi(),
    RestaurantStatsApi(),
    RestaurantApi(),
    ReservationApi(),
    RestaurantTypeApi(),
    CousinsApi(),
    FeaturesApi(),
    TagsApi(),
    RestaurantImageApi(),
    OffersApi(),
    MenuImageApi(),
    AboutApi(),
    LocationApi(),
    CustomerApi(),
  ]);
}

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { ADDTABLE, LIST_TABLE } from "redux/constants/TableConstants";
import {
  addTable,
  tabledetails,
  getFloorDetail,
  getTableTypeDetail,
} from "redux/actions/TableAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import {message} from 'antd';

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";

if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertTableData() {
  yield takeEvery(ADDTABLE, function* ({ payload }) {

    try {
      const res = yield axios.post(`/tablegenerrate/${rest_id}`, payload);

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getTable() {
  yield takeEvery(LIST_TABLE, function* () {
    try {
      const res = yield axios.get(`/table/${rest_id}`);
      if (res.status === 200) {
        yield put(tabledetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(insertTableData), fork(getTable)]);
}

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_MENU_IMAGE,
  LIST_MENU_IMAGE,
  MENU_IMAGE_DELETE,
  UPDATE_MENU_IMAGE,
} from "redux/constants/MenuConstants";
import { menuimagedetails } from "redux/actions/MenuAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from "antd";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertMenuImageData() {
  yield takeEvery(ADD_MENU_IMAGE, function* ({ payload }) {
    console.log("payload", payload);
    try {
      const res = yield axios.post(
        `/add_restaurant_menuimages/${rest_id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("res", res);
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getMenuImage() {
  yield takeEvery(LIST_MENU_IMAGE, function* () {
    try {
      const res = yield axios.get(`/get_restaurant_menuimages/${rest_id}`);
      if (res.status === 200) {
        yield put(menuimagedetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateMenuImage() {
  yield takeEvery(UPDATE_MENU_IMAGE, function* ({ payload }) {
    console.log("updatepayload", payload);
    try {
      const res = yield axios.post(
        `/update_restaurant_menuimages/${payload.menu_image_id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* menuImageDeleteData() {
  yield takeEvery(MENU_IMAGE_DELETE, function* ({ payload }) {
    try {
      const res = yield axios.get(`/delete_restaurant_menuimages/${payload}`);

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(insertMenuImageData),
    fork(getMenuImage),
    fork(updateMenuImage),
    fork(menuImageDeleteData),
  ]);
}

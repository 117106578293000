import {
  ADD_RESTAURANT_TYPE,
  LIST_RESTAURANT_TYPE,
  RESTAURANT_TYPE_DETAILS,
  RESTAURANT_TYPE_DELETE,
  UPDATE_RESTAURANT_TYPE,
  ASSIGN_RESTAURANT_TYPE,
  ASSIGN_RESTAURANT_TYPE_DETAILS,
  LIST_ASSIGN_RESTAURANT_TYPE,
} from "redux/constants/RestaurantTypeConstants";

export const addRestaurantType = (data) => {
  return {
    type: ADD_RESTAURANT_TYPE,
    payload: data,
  };
};

export const listrestauranttype = (payload) => {
  return {
    type: LIST_RESTAURANT_TYPE,
    payload,
  };
};

export const restauranttypedetails = (data) => {
  return {
    type: RESTAURANT_TYPE_DETAILS,
    payload: data,
  };
};

export const restauranttypedelete = (id) => {
  return {
    type: RESTAURANT_TYPE_DELETE,
    payload: id,
  };
};

export const updaterestauranttype = (data) => {
  return {
    type: UPDATE_RESTAURANT_TYPE,
    payload: data,
  };
};

export const assignRestaurantType = (data) => {
  return {
    type: ASSIGN_RESTAURANT_TYPE,
    payload: data,
  };
};

export const listassignrestauranttype = () => {
  return {
    type: LIST_ASSIGN_RESTAURANT_TYPE,
  };
};

export const assignrestauranttypedetails = (data) => {
  return {
    type: ASSIGN_RESTAURANT_TYPE_DETAILS,
    payload: data,
  };
};

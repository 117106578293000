import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import axios from "axios";
import { SWRConfig } from "swr";

import { AUTH_TOKEN } from "./redux/constants/Auth";
import { notification } from "antd";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
// axios.defaults.baseURL = "https://pandoratest.wegsoft.com/api/";
// axios.defaults.baseURL = "http://192.168.29.133:8000/api/";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
// https://api-reserve.wegsoft.com/api
// axios.defaults.baseURL = "http://192.168.1.7:8000/api/";

axios.interceptors.request.use(function (config) {
  const bearertoken = localStorage.getItem(AUTH_TOKEN);
  config.headers.Authorization = bearertoken ? `Bearer ${bearertoken}` : "";
  return config;
});

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <SWRConfig
            value={{
              revalidateOnFocus: false,
              revalidateOnReconnect: false,
              refreshWhenOffline: false,
              refreshWhenHidden: false,
              refreshInterval: 0,
              fetcher: (resource, init) =>
                axios(resource, init).then((res) => res?.data || res),
              onError: async (error) => {
                // if (error?.status === 401) {
                //   deleteCookie("token");
                //   // await logout();
                // }

                if (error?.status === 404) {
                  notification.error({
                    message: "Resource unavailable. Please try again later",
                  });
                }
                if (error?.status === 400) {
                  notification.error({
                    message: error?.message || error?.data?.message,
                  });
                }
              },
            }}
          >
            <Router>
              <Switch>
                <Route path="/" component={Views} />
              </Switch>
            </Router>
          </SWRConfig>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;

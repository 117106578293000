export const MENU_IMAGE = "MENU_IMAGE";

export const ADD_MENU_IMAGE = "ADD_MENU_IMAGE";

export const LIST_MENU_IMAGE = "LIST_MENU_IMAGE";

export const MENU_IMAGE_DETAILS = "MENU_IMAGE_DETAILS";

export const MENU_IMAGE_DELETE = "MENU_IMAGE_DELETE";

export const UPDATE_MENU_IMAGE = "UPDATE_MENU_IMAGE";

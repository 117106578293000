import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_ABOUT,
  LIST_ABOUT,
  ABOUT_DELETE,
  UPDATE_ABOUT,
} from "redux/constants/AboutConstants";
import { aboutdetails } from "redux/actions/AboutAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from "antd";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertAboutData() {
  yield takeEvery(ADD_ABOUT, function* ({ payload }) {
    try {
      const res = yield axios.post(`/add_about_details/${rest_id}`, payload);
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getAbout() {
  yield takeEvery(LIST_ABOUT, function* () {
    try {
      const res = yield axios.get(`/get_about_details/${rest_id}`);
      if (res.status === 200) {
        yield put(aboutdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateAbout() {
  yield takeEvery(UPDATE_ABOUT, function* ({ payload }) {
    console.log("updatepayload", payload);
    try {
      const res = yield axios.post(
        `/update_about_details/${payload.about_id}`,
        payload
      );
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(insertAboutData), fork(getAbout), fork(updateAbout)]);
}

export const OFFERS = "OFFERS";

export const ADD_OFFERS = "ADD_OFFERS";

export const LIST_OFFERS = "LIST_OFFERS";

export const OFFERS_DETAILS = "OFFERS_DETAILS";

export const OFFERS_DELETE = "OFFERS_DELETE";

export const UPDATE_OFFERS = "UPDATE_OFFERS";

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADDFLOOR,
  LIST_FLOOR,
  FLOOR_DELETE,
  LIST_TABLE_FLOOR,
  UPDATE_FLOOR,
} from "../constants/FloorConstants";
import {
  floordetails,
  tablefloordetails,
  showFloorMessage,
  showFloorFailedMessage,
  show,
  showDeleteMessage
} from "../actions/FloorAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import {message} from 'antd';

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertFloorData() {
  yield takeEvery(ADDFLOOR, function* ({ payload }) {
    const { title, status } = payload;
    try {
      const res = yield axios.post(`/floor_store/${rest_id}`, payload);
      console.log("res", res);
      if (res.data.status === 200) {
        message.success(res.data.message);
      } else {
        yield put(showFloorFailedMessage(res.data.message));
      }
      
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getFloor() {
  yield takeEvery(LIST_FLOOR, function* () {
    try {
      const res = yield axios.get(`/get_floor/${rest_id}`);
      if (res.status === 200) {
        yield put(floordetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* floorDelete() {
  yield takeEvery(FLOOR_DELETE, function* ({ payload }) {
    try {
      const res = yield axios.get(`/floor_delete/${payload}`);

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getTableFloor() {
  yield takeEvery(LIST_TABLE_FLOOR, function* () {
    try {
      const res = yield axios.get(`/get_floor_app/${rest_id}`);
      if (res.status === 200) {
        yield put(tablefloordetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateFloor() {
  yield takeEvery(UPDATE_FLOOR, function* ({ payload }) {
    try {
      const res = yield axios.post(`/floor_update/${payload.id}`, payload);
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(insertFloorData),
    fork(getFloor),
    fork(floorDelete),
    fork(getTableFloor),
    fork(updateFloor),
  ]);
}

import {
    RESTAURANT_STATS,
    RESTAURANT_STATS_DETAILS,
  } from "redux/constants/RestaurantStats";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
    loading: false,
    message: "",
    redirect: "",
    showMessage: false,
    token: localStorage.getItem(AUTH_TOKEN),
    data: "",
  };

  const RestaurantStatsReducer = (state = initState, action) => {
    switch (action.type) {
      case RESTAURANT_STATS:
        return {
          ...state,
          redirect: "",
          token: action.token,
          restStatsData: action.payload,
        };
      case RESTAURANT_STATS_DETAILS: {
        return {
          ...state,
          redirect: "",
          restStatsData: action.payload,
          token: action.token,
        };
      }
      default:
        return state;
    }
  };
  
  export default RestaurantStatsReducer;
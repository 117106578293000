import {
  ADD_CUISINE,
  LIST_CUISINE,
  CUISINE_DETAILS,
  CUISINE_DELETE,
  UPDATE_CUISINE,
  ASSIGN_CUISINE,
  LIST_ASSIGN_CUISINE,
  ASSIGN_CUISINE_DETAILS,
} from "redux/constants/CuisineConstants";

export const addCuisine = (data) => {
  return {
    type: ADD_CUISINE,
    payload: data,
  };
};

export const listCuisine = () => {
  return {
    type: LIST_CUISINE,
  };
};

export const cuisinedetails = (data) => {
  return {
    type: CUISINE_DETAILS,
    payload: data,
  };
};

export const cuisinedelete = (id) => {
  return {
    type: CUISINE_DELETE,
    payload: id,
  };
};

export const updateCuisine = (data) => {
  return {
    type: UPDATE_CUISINE,
    payload: data,
  };
};

export const assignCuisine = (data) => {
  return {
    type: ASSIGN_CUISINE,
    payload: data,
  };
};

export const listAssignCuisine = () => {
  return {
    type: LIST_ASSIGN_CUISINE,
  };
};

export const cuisineassigndetails = (data) => {
  return {
    type: ASSIGN_CUISINE_DETAILS,
    payload: data,
  };
};

import {
  ADDRESTAURANT,
  RESTAURANT,
  RESTAURANT_DETAILS,
  RESTAURANT_DELETE,
  UPDATE_RESTAURANT,
  GET_VENDOR,
  GET_VENDOR_DETAILS,
} from "redux/constants/RestaurantConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
};

const RestaurantReducer = (state = initState, action) => {
  switch (action.type) {
    case ADDRESTAURANT:
      return {
        ...state,
        redirect: "",
        token: action.token,
        restaurantData: action.restaurantData,
      };
    case RESTAURANT:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case RESTAURANT_DETAILS: {
      return {
        ...state,
        redirect: "",
        restaurantData: action.restdata,
        token: action.token,
      };
    }

    case RESTAURANT_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };

    case UPDATE_RESTAURANT: {
      return {
        ...state,
        redirect: "",
        restaurantData: action.restaurantData,
        token: action.token,
      };
    }

    case GET_VENDOR:
      return {
        ...state,
        redirect: "",
        token: action.token,
        // vendorData: action.payload,
      };

    case GET_VENDOR_DETAILS: {
      return {
        ...state,
        redirect: "",
        vendorData: action.payload,
        token: action.token,
      };
    }

    default:
      return state;
  }
};

export default RestaurantReducer;

import {
  ADD_RESTAURANT_IMAGE,
  LIST_RESTAURANT_IMAGE,
  RESTAURANT_IMAGE_DETAILS,
  RESTAURANT_IMAGE_DELETE,
  UPDATE_RESTAURANT_IMAGE,
} from "redux/constants/RestaurantImageConstants";

export const addRestaurantImage = (data) => {
  return {
    type: ADD_RESTAURANT_IMAGE,
    payload: data,
  };
};

export const listrestaurantimage = () => {
  return {
    type: LIST_RESTAURANT_IMAGE,
  };
};

export const restaurantimagedetails = (data) => {
  return {
    type: RESTAURANT_IMAGE_DETAILS,
    payload: data,
  };
};

export const restaurantimagedelete = (id) => {
  return {
    type: RESTAURANT_IMAGE_DELETE,
    payload: id,
  };
};

export const updaterestaurantimage = (data) => {
  return {
    type: UPDATE_RESTAURANT_IMAGE,
    payload: data,
  };
};

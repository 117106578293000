import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADDRESTAURANT,
  LIST_RESTAURANT,
  RESTAURANT_DELETE,
  UPDATE_RESTAURANT,
  GET_VENDOR,
  RESTAURANT,
} from "redux/constants/RestaurantConstants";
import {
  addRestaurant,
  restaurantdetails,
  vendordetails,
} from "redux/actions/RestaurantAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from "antd";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";

if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertRestaurantData() {
  yield takeEvery(ADDRESTAURANT, function* ({ payload }) {
    try {
      const res = yield axios.post(
        `/getvendor_restaurant_store_update`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getRestaurant() {
  yield takeEvery(LIST_RESTAURANT, function* () {
    try {
      const res = yield axios.get(`/getvendor_restaurant`);
      if (res.status === 200) {
        yield put(restaurantdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getVendor() {
  yield takeEvery(GET_VENDOR, function* () {
    try {
      const res = yield axios.get(`/getvendor/${rest_id}`);
      if (res.status === 200) {
        yield put(vendordetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateRestaurant() {
  yield takeEvery(UPDATE_RESTAURANT, function* ({ payload }) {
    console.log("payloadd", payload);
    try {
      const res = yield axios.post(
        `/getvendor_restaurant_store_update/${payload.id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getVendor),
    fork(insertRestaurantData),
    fork(getRestaurant),
    fork(updateRestaurant),
  ]);
}

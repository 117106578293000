import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import FloorReducer from "./FloorReducer";
import TableTypeReducer from "./TableTypeReducer";
import TableReducer from "./TableReducer";
import RestaurantStatsReducer from "./RestaurantStatsReducer";
import RestaurantReducer from "./RestaurantReducer";
import ReservationReducer from "./ReservationReducer";
import RestaurantTypeReducer from "./RestaurantTypeReducer";
import CuisineReducer from "./CuisineReducer";
import FeaturesReducer from "./FeaturesAction";
import TagsReducer from "./TagsReducer";
import RestaurantImageReducer from "./RestaurantImageReducer";
import OffersReducer from "./OffersReducer";
import MenuImageReducer from "./MenuReducer";
import AboutReducer from "./AboutReducer";
import LocationReducer from "./LocationReducer";
import CustomerReducer from "./CustomerReducer";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  floor: FloorReducer,
  tabletype: TableTypeReducer,
  table: TableReducer,
  reststats: RestaurantStatsReducer,
  restaurant: RestaurantReducer,
  reservation: ReservationReducer,
  restaurantType: RestaurantTypeReducer,
  cuisine: CuisineReducer,
  features: FeaturesReducer,
  tags: TagsReducer,
  restaurantimage: RestaurantImageReducer,
  offers: OffersReducer,
  menuimage: MenuImageReducer,
  about: AboutReducer,
  location: LocationReducer,
  customer: CustomerReducer,
});

export default reducers;

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_CUISINE,
  LIST_CUISINE,
  CUISINE_DELETE,
  UPDATE_CUISINE,
  ASSIGN_CUISINE,
  ASSIGN_CUISINE_DETAILS,
  LIST_ASSIGN_CUISINE,
} from "redux/constants/CuisineConstants";
import {
  cuisinedetails,
  cuisineassigndetails,
} from "redux/actions/CuisineAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import {message} from 'antd';

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertCousinData() {
  yield takeEvery(ADD_CUISINE, function* ({ payload }) {
    try {
      const res = yield axios.post(`/add_cousins`, payload);

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getCousins() {
  yield takeEvery(LIST_CUISINE, function* () {
    try {
      const res = yield axios.get(`/get_cousins`);
      if (res.status === 200) {
        yield put(cuisinedetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* cuisineDelete() {
  yield takeEvery(CUISINE_DELETE, function* ({ payload }) {
    try {
      const res = yield axios.get(`/delete_cousins/${payload}`);

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateCousin() {
  yield takeEvery(UPDATE_CUISINE, function* ({ payload }) {
    try {
      const res = yield axios.post(
        `/update_cousins/${payload.cousin_id}`,
        payload
      );
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* assignCousinData() {
  yield takeEvery(ASSIGN_CUISINE, function* ({ payload }) {
    const data = { id: payload };

    try {
      const res = yield axios.post(
        `/assign_restaurant_cousins/${rest_id}`,
        data
      );

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getAssignCousins() {
  yield takeEvery(LIST_ASSIGN_CUISINE, function* () {
    try {
      const res = yield axios.get(`/get_cousins_by_restaurant_id/${rest_id}`);
      if (res.status === 200) {
        yield put(cuisineassigndetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(insertCousinData),
    fork(getCousins),
    fork(updateCousin),
    fork(cuisineDelete),
    fork(assignCousinData),
    fork(getAssignCousins),
  ]);
}

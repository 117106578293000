import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_TAGS,
  LIST_TAGS,
  TAGS_DELETE,
  UPDATE_TAGS,
  ASSIGN_TAGS,
  ASSIGN_TAGS_DETAILS,
  LIST_ASSIGN_TAGS,
} from "redux/constants/TagsConstants";
import { tagsdetails, tagsassigndetails } from "redux/actions/TagsAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from 'antd';

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertTags() {
  yield takeEvery(ADD_TAGS, function* ({ payload }) {
    console.log("payload", payload);
    try {
      const res = yield axios.post(`/add_tags`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("res", res);
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getTags() {
  yield takeEvery(LIST_TAGS, function* () {
    try {
      const res = yield axios.get(`/tags`);
      if (res.status === 200) {
        yield put(tagsdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateTagsData() {
  yield takeEvery(UPDATE_TAGS, function* ({ payload }) {
    console.log("updatepayload", payload);

    try {
      const res = yield axios.post(`/update_tags/${payload.tag_id}`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* assignTagsData() {
  yield takeEvery(ASSIGN_TAGS, function* ({ payload }) {
    const data = { id: payload };

    try {
      const res = yield axios.post(`/assign_restaurant_tag/${rest_id}`, data);

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getAssignTags() {
  yield takeEvery(LIST_ASSIGN_TAGS, function* () {
    try {
      const res = yield axios.get(`/get_tags_by_restaurant_id/${rest_id}`);
      if (res.status === 200) {
        yield put(tagsassigndetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* tagsDeleteData() {
  yield takeEvery(TAGS_DELETE, function* ({ payload }) {
    console.log("payload dele", payload);
    try {
      const res = yield axios.get(`/delete_tags/${payload}`);

      if (res.status === 200) {
        // yield put(showFeatureMessage(res.data.message));
        message.success(res.data.message);
        // yield put(floordetails());
        // fork(getFloor);
        console.log(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(insertTags),
    fork(getTags),
    fork(updateTagsData),
    fork(assignTagsData),
    fork(getAssignTags),
    fork(tagsDeleteData),
  ]);
}

export const TAGS = "TAGS";

export const ADD_TAGS = "ADD_TAGS";

export const LIST_TAGS = "LIST_TAGS";

export const TAGS_DETAILS = "TAGS_DETAILS";

export const TAGS_DELETE = "TAGS_DELETE";

export const UPDATE_TAGS = "UPDATE_TAGS";

export const ASSIGN_TAGS = "ASSIGN_TAGS";

export const LIST_ASSIGN_TAGS = "LIST_ASSIGN_TAGS";

export const ASSIGN_TAGS_DETAILS = "ASSIGN_TAGS_DETAILS";

export const RESTAURANT = 'RESTAURANT'; 

export const ADDRESTAURANT = 'ADDRESTAURANT'; 

export const LIST_RESTAURANT = 'LIST_RESTAURANT'

export const RESTAURANT_DETAILS = 'RESTAURANT_DETAILS'

export const RESTAURANT_DELETE = 'RESTAURANT_DELETE'

export const UPDATE_RESTAURANT = 'UPDATE_RESTAURANT'

export const GET_VENDOR = 'GET_VENDOR'

export const GET_VENDOR_DETAILS = 'GET_VENDOR_DETAILS'


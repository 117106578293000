export const LOCATION = "LOCATION";

export const ADD_LOCATION = "ADD_LOCATION";

export const LIST_LOCATION = "LIST_LOCATION";

export const LOCATION_DETAILS = "LOCATION_DETAILS";

export const LOCATION_DELETE = "LOCATION_DELETE";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
import {
  ADD_ABOUT,
  LIST_ABOUT,
  ABOUT_DETAILS,
  ABOUT_DELETE,
  UPDATE_ABOUT,
} from "redux/constants/AboutConstants";

export const addAbout = (data) => {
  return {
    type: ADD_ABOUT,
    payload: data,
  };
};

export const listabout = () => {
  return {
    type: LIST_ABOUT,
  };
};

export const aboutdetails = (data) => {
  return {
    type: ABOUT_DETAILS,
    payload: data,
  };
};

export const aboutdelete = (id) => {
  return {
    type: ABOUT_DELETE,
    payload: id,
  };
};

export const updateabout = (data) => {
  return {
    type: UPDATE_ABOUT,
    payload: data,
  };
};

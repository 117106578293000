export const ABOUT = "ABOUT";

export const ADD_ABOUT = "ADD_ABOUT";

export const LIST_ABOUT = "LIST_ABOUT";

export const ABOUT_DETAILS = "ABOUT_DETAILS";

export const ABOUT_DELETE = "ABOUT_DELETE";

export const UPDATE_ABOUT = "UPDATE_ABOUT";

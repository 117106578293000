import {
  ADDTABLE,
  LIST_TABLE,
  TABLE_DETAILS,
  GET_FLOOR,
  GET_FLOOR_DETAILS,
  GET_TABLE_TYPE,
  GET_TABLE_TYPE_DETAILS,
} from "redux/constants/TableConstants";

export const addTable = (data) => {
  return {
    type: ADDTABLE,
    payload: data,
  };
};

export const listtable = () => {
  return {
    type: LIST_TABLE,
  };
};

export const tabledetails = (data) => {
  return {
    type: TABLE_DETAILS,
    payload: data,
  };
};

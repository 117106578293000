export const CUISINE = "CUISINE";

export const ADD_CUISINE = "ADD_CUISINE";

export const LIST_CUISINE = "LIST_CUISINE";

export const CUISINE_DETAILS = "CUISINE_DETAILS";

export const CUISINE_DELETE = "CUISINE_DELETE";

export const UPDATE_CUISINE = "UPDATE_CUISINE";

export const ASSIGN_CUISINE = "ASSIGN_CUISINE";

export const LIST_ASSIGN_CUISINE = "LIST_ASSIGN_CUISINE";

export const ASSIGN_CUISINE_DETAILS = "ASSIGN_CUISINE_DETAILS";

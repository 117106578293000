import {
  ADD_CUSTOMER,
  LIST_CUSTOMER,
  CUSTOMER_DETAILS,
  CUSTOMER_DELETE,
} from "redux/constants/CustomerConstants";

export const addCustomer = (data) => {
  return {
    type: ADD_CUSTOMER,
    payload: data,
  };
};

export const listCustomer = (data) => {
  return {
    type: LIST_CUSTOMER,
    payload: data,
  };
};

export const customerDetails = (data) => {
  return {
    type: CUSTOMER_DETAILS,
    payload: data,
  };
};

export const customerDelete = (id) => {
  return {
    type: CUSTOMER_DELETE,
    payload: id,
  };
};

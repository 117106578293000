import { Button, Card, Divider, Space, message } from "antd";
import { publicReserve } from "api/widget";
import clsx from "clsx";
import moment from "moment";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useReactToPrint } from "react-to-print";
import useSWR from "swr";
import { BookingForm } from "./BookingForm";
import QRGenerate from "./QRGenerate";

export default function Widget() {
  const { slack } = useParams();
  const qrRef = useRef();
  const [bookingId, setBookingId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  // fetch data
  const { data, isLoading } = useSWR(`/restaurant_widget?slack=${slack}`);
  const { data: dates, isLoading: loadingDates } = useSWR(
    selectedDate
      ? `/restaurant_widget?slack=${slack}&date=${selectedDate}`
      : null
  );

  function locationFormat() {
    let location = "";
    if (data) {
      let locationArr = [];
      locationArr.push(
        data.restaurant.street,
        data.restaurant.city,
        data.restaurant.state,
        data.restaurant.country
      );
      locationArr.filter((v) => !!v);
      location = locationArr.join(", ");
    }
    return location;
  }

  function submitHandler(data, reset) {
    const dto = {
      ...data,
      reserve_date: moment(data.reserve_date).format("YYYY-MM-DD"),
      slack,
    };

    publicReserve(dto)
      .then((res) => {
        message.success(res?.data?.message);
        reset();
        setBookingId(res?.data?.reservation);
      })
      .catch(({ response }) => message.error(response.data.message));
  }

  const handlePrint = useReactToPrint({
    content: () => qrRef.current,
  });

  return (
    <Card
      style={{ maxWidth: "800px", borderColor: "#3e79f7" }}
      loading={isLoading}
    >
      <div ref={qrRef} className={clsx(bookingId ? "p-4" : "")}>
        <Space size={20}>
          <img
            width="150px"
            height="150px"
            style={{ objectFit: "cover", borderRadius: "5px" }}
            src={
              process.env.REACT_APP_IMAGE_URL + data?.restaurant?.profile_image
            }
          />
          <section>
            <h3 className="font-weight-bold">{data?.restaurant?.title}</h3>
            <p>{locationFormat()}</p>
          </section>
        </Space>
        <Divider />
        {bookingId ? (
          <QRGenerate form={bookingId} />
        ) : (
          <BookingForm
            submit={submitHandler}
            loadingDates={loadingDates}
            setSelectedDate={setSelectedDate}
            loading={isLoading}
            times={
              dates
                ? [...dates?.lunch, ...dates.dinner]
                : data
                ? [...data?.lunch, ...data?.dinner]
                : []
            }
          />
        )}
        <footer className="text-right mt-3">
          <p>
            Powered By{" "}
            <a href="https://wegsoft.com" target="_blank">
              © {moment().format("YYYY")} Wegsoft Technologies Pvt Ltd
            </a>
          </p>
        </footer>
      </div>
      {bookingId ? (
        <Button type="primary" size="large" onClick={handlePrint}>
          Download QR
        </Button>
      ) : null}
    </Card>
  );
}

import { CheckOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Input, Modal, Row } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { onLocaleChange } from "redux/actions/Theme";
import { LOGIN_DATA } from "redux/constants/Auth";
import { IMAGE_PREFIX } from "redux/constants/ImagePrefix";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

const onChangeRestaurant = (id) => {
  userD.restaurant_id = id;
  localStorage.setItem(LOGIN_DATA, JSON.stringify(userD));
  window.location.reload();
};

export const NavLanguage = ({ data }) => {
  const [search, setSearch] = useState("");

  const renderList = data
    ?.filter(({ title }) => title.match(new RegExp(search, "i")))
    .map((item) => {
      return (
        <Col key={item.id} span={8}>
          <Card
            hoverable
            value={item.id}
            title={
              <>
                <Row style={{ alignItems: "center" }}>
                  <Avatar
                    className="mr-2"
                    size={64}
                    src={IMAGE_PREFIX + item.profile_image}
                  />
                  <h4 className="mb-0">
                    {item.title}
                    {rest_id == item.id ? (
                      <CheckOutlined
                        className="ml-2 text-success"
                        style={{ float: "right", fontSize: "20px" }}
                      />
                    ) : null}{" "}
                  </h4>
                </Row>
              </>
            }
            onClick={() => onChangeRestaurant(item.id)}
            style={{ borderWidth: 1, borderColor: "#7F8487" }}
          ></Card>
        </Col>
      );
    });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* <div>
        <Select
          allowClear
          showSearch
          style={{
            width: 200,
          }}
          placeholder="Select your Restaurant"
          onChange={onChangeRestaurant}
          onSearch={onSearch}
          value={rest_id}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {renderList}
        </Select>
      </div> */}

      <div>
        <Button type="text" onClick={showModal}>
          Switch Restaurant
        </Button>
        <Modal
          title="Select Restaurant"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1500}
        >
          <div style={{ marginBottom: 10 }}>
            <Input
              placeholder="Search your Restaurant"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>

          <div
            className="site-card-wrapper"
            style={{ overflow: "auto", overflowX: "hidden", height: "31.25vw" }}
          >
            <Row gutter={[16, 16]}>{renderList}</Row>
          </div>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps, { onLocaleChange })(NavLanguage);

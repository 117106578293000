import {
  ADD_CUSTOMER,
  CUSTOMER,
  CUSTOMER_DETAILS,
  CUSTOMER_DELETE,
} from "redux/constants/CustomerConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const CustomerReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER:
      return {
        ...state,
        redirect: "",
        token: action.token,
        customerData: action.customerData,
      };
    case CUSTOMER:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case CUSTOMER_DETAILS: {
      return {
        ...state,
        redirect: "",
        customerData: action.payload,
        token: action.token,
      };
    }
    case CUSTOMER_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default CustomerReducer;

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_LOCATION,
  LIST_LOCATION,
  LOCATION_DELETE,
  UPDATE_LOCATION,
} from "redux/constants/LocationConstants";
import { listlocation, locationdetails } from "redux/actions/LocationAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from "antd";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertLocationData() {
  yield takeEvery(ADD_LOCATION, function* ({ payload }) {
    console.log("payload", payload);
    try {
      const res = yield axios.post(
        `/add_location/${rest_id}/1`,
        payload
      );
      console.log("res", res);
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getLocation() {
  yield takeEvery(LIST_LOCATION, function* () {
    try {
      const res = yield axios.get(`/get_location/${rest_id}/1`);
      if (res.status === 200) {
        yield put(locationdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateLocation() {
  yield takeEvery(UPDATE_LOCATION, function* ({ payload }) {
    console.log("updatepayload", payload);
    try {
      const res = yield axios.post(
        `/update_location/${payload.id}`,
        payload
      );
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(insertLocationData),
    fork(getLocation),
    fork(updateLocation),
  ]);
}

// 15.39959547687594, 74.01753565806267
export const RESERVATION = "RESERVATION";

export const ADDRESERVATION = "ADDRESERVATION";

export const LIST_RESERVATION = "LIST_RESERVATION";

export const RESERVATION_DETAILS = "RESERVATION_DETAILS";

export const RESERVEBY = "RESERVEBY";

export const LIST_RESERVE_BY = "LIST_RESERVE_BY";

export const RESERVE_BY_DETAILS = "RESERVE_BY_DETAILS";

export const SOURCE = "SOURCE";

export const LIST_SOURCE_BY = "LIST_SOURCE_BY";

export const SOURCE_BY_DETAILS = "SOURCE_BY_DETAILS";

export const LIST_WALKIN = "LIST_WALKIN";

export const LIST_WALKIN_DETAILS = "LIST_WALKIN_DETAILS";

export const LIST_WAITING = "LIST_WAITING";

export const LIST_WAITING_DETAILS = "LIST_WAITING_DETAILS";

export const LIST_HISTORY_RESERVATION = "LIST_HISTORY_RESERVATION";

export const LIST_HISTORY_RESERVATION_DETAILS =
  "LIST_HISTORY_RESERVATION_DETAILS";

export const GET_USER_PHONE = "GET_USER_PHONE";

export const GET_USER_PHONE_DETAILS = "GET_USER_PHONE_DETAILS";

export const LIST_DELETED_RESERVATION = 'LIST_DELETED_RESERVATION'

export const DELETED_RESERVATION_DETAILS = 'DELETED_RESERVATION_DETAILS'

import {
  ADDFLOOR,
  LIST_FLOOR,
  FLOOR_DETAILS,
  FLOOR_DELETE,
  LIST_TABLE_FLOOR,
  TABLE_FLOOR_DETAILS,
  UPDATE_FLOOR,
  SHOW_FLOOR_MESSAGE,
  HIDE_FLOOR_MESSAGE,
  SHOW_FLOOR_FAILED_MESSAGE,
  HIDE_FLOOR_FAILED_MESSAGE,
  SHOW_FLOOR_DELETE_MESSAGE,
  HIDE_FLOOR_DELETE_MESSAGE,
} from "../constants/FloorConstants";

export const addFloor = (data) => {
  return {
    type: ADDFLOOR,
    payload: data,
  };
};

export const listfloor = () => {
  return {
    type: LIST_FLOOR,
  };
};

export const floordetails = (data) => {
  return {
    type: FLOOR_DETAILS,
    payload: data,
  };
};

export const floordelete = (id) => {
  return {
    type: FLOOR_DELETE,
    payload: id,
  };
};

export const listtablefloor = () => {
  return {
    type: LIST_TABLE_FLOOR,
  };
};

export const tablefloordetails = (data) => {
  return {
    type: TABLE_FLOOR_DETAILS,
    payload: data,
  };
};

export const updatefloor = (data) => {
  return {
    type: UPDATE_FLOOR,
    payload: data,
  };
};

export const showFloorMessage = (message) => {
  return {
    type: SHOW_FLOOR_MESSAGE,
    message,
  };
};

export const hideFloorMessage = () => {
  return {
    type: HIDE_FLOOR_MESSAGE,
  };
};

export const showFloorFailedMessage = (message) => {
  return {
    type: SHOW_FLOOR_FAILED_MESSAGE,
    message,
  };
};

export const hideFloorFailedMessage = () => {
  return {
    type: HIDE_FLOOR_FAILED_MESSAGE,
  };
};

export const showDeleteMessage = (message) => {
  return {
    type: SHOW_FLOOR_DELETE_MESSAGE,
    message,
  };
};

export const hideDeleteMessage = () => {
  return {
    type: HIDE_FLOOR_DELETE_MESSAGE,
  };
};
import {
  ADDRESTAURANT,
  LIST_RESTAURANT,
  RESTAURANT_DETAILS,
  RESTAURANT_DELETE,
  UPDATE_RESTAURANT,
  GET_VENDOR,
  GET_VENDOR_DETAILS,
} from "redux/constants/RestaurantConstants";

export const addRestaurant = (data) => {
  return {
    type: ADDRESTAURANT,
    payload: data,
  };
};

export const listrestaurant = () => {
  return {
    type: LIST_RESTAURANT,
  };
};

export const restaurantdetails = (data) => {
  return {
    type: RESTAURANT_DETAILS,
    restdata: data,
  };
};

export const restaurantdelete = (id) => {
  return {
    type: RESTAURANT_DELETE,
    payload: id,
  };
};

export const updaterestaurant = (data) => {
  return {
    type: UPDATE_RESTAURANT,
    payload: data,
  };
};

export const getvendor = () => {
  return {
    type: GET_VENDOR,
    // payload: data,
  };
};

export const vendordetails = (data) => {
  return {
    type: GET_VENDOR_DETAILS,
    payload: data,
  };
};

import {
  ADDRESERVATION,
  LIST_RESERVATION,
  RESERVATION_DETAILS,
  LIST_RESERVE_BY,
  RESERVE_BY_DETAILS,
  LIST_SOURCE_BY,
  SOURCE_BY_DETAILS,
  LIST_WALKIN,
  LIST_WALKIN_DETAILS,
  LIST_WAITING,
  LIST_WAITING_DETAILS,
  LIST_HISTORY_RESERVATION,
  LIST_HISTORY_RESERVATION_DETAILS,
  GET_USER_PHONE,
  GET_USER_PHONE_DETAILS,
  DELETED_RESERVATION_DETAILS,
  LIST_DELETED_RESERVATION
} from "../constants/ReservationConstants";

export const addreservation = (data) => {
  return {
    type: ADDRESERVATION,
    payload: data,
  };
};

export const listreservation = (data) => {
  return {
    type: LIST_RESERVATION,
    payload: data,
  };
};

export const reservationdetails = (data) => {
  return {
    type: RESERVATION_DETAILS,
    payload: data,
  };
};

export const listreserveby = () => {
  return {
    type: LIST_RESERVE_BY,
  };
};

export const reservebydetails = (data) => {
  return {
    type: RESERVE_BY_DETAILS,
    payload: data,
  };
};

export const listsourceby = () => {
  return {
    type: LIST_SOURCE_BY,
  };
};

export const sourcebydetails = (data) => {
  return {
    type: SOURCE_BY_DETAILS,
    payload: data,
  };
};

export const listwalkin = (data) => {
  return {
    type: LIST_WALKIN,
    payload: data,
  };
};

export const walkindetails = (data) => {
  return {
    type: LIST_WALKIN_DETAILS,
    payload: data,
  };
};

export const listwaiting = () => {
  return {
    type: LIST_WAITING,
  };
};

export const waitingdetails = (data) => {
  return {
    type: LIST_WAITING_DETAILS,
    payload: data,
  };
};

export const listhistoryreservation = (data) => {
  return {
    type: LIST_HISTORY_RESERVATION,
    payload: data,
  };
};

export const historyreservationdetails = (data) => {
  return {
    type: LIST_HISTORY_RESERVATION_DETAILS,
    payload: data,
  };
};

export const getuserphone = (phone) => {
  return {
    type: GET_USER_PHONE,
    payload: phone,
  };
};

export const getuserphonedetails = (name) => {
  return {
    type: GET_USER_PHONE_DETAILS,
    payload: name,
  };
};

export const listDeletedReservation = (data) => {
  return {
    type: LIST_DELETED_RESERVATION,
    payload: data,
  };
};

export const deletedReservationDetails = (data) => {
  return {
    type: DELETED_RESERVATION_DETAILS,
    payload: data,
  };
};

import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu, Space } from "antd";
import Icon from "components/util-components/Icon";
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { signOut } from "redux/actions/Auth";
import ChangePassword from "views/app-views/components/data-entry/form/ChangePassword";

const menuItem = [
  // {
  //   title: "Edit Profile",
  //   icon: EditOutlined,
  //   path: "/",
  // },
  // {
  //   title: "Account Setting",
  //   icon: SettingOutlined,
  //   path: "/",
  // },
  // {
  //   title: "Billing",
  //   icon: ShopOutlined,
  //   path: "/",
  // },
  // {
  //   title: "Help Center",
  //   icon: QuestionCircleOutlined,
  //   path: "/",
  // },
];

export const NavProfile = ({ signOut }) => {
  const [changePassToggle, setChangePassToggle] = useState(false);
  const loginInfo = useSelector((state) => state.auth);

  const profileImg = "/img/avatars/thumb-1.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar
            size={45}
            style={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
            }}
          >
            {Array.from(loginInfo.user.name)[0]}
          </Avatar>
          <div className="pl-3">
            <h4 className="mb-0">{loginInfo.user.name}</h4>
            <span className="text-muted">
              {loginInfo.user.user_type == 1
                ? "Super-Admin"
                : loginInfo.user.user_type == 2
                ? "Admin"
                : loginInfo.user.user_type == 3 && "Employee"}
            </span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={(e) => setChangePassToggle(true)}
          >
            <span>
              <LogoutOutlined className="mr-3 invisible" />
              <span className="font-weight-normal">Change Password</span>
            </span>
          </Menu.Item>
          <Menu.Item key={menuItem.length + 2} onClick={(e) => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <>
      <Dropdown
        placement="bottomRight"
        overlay={profileMenu}
        trigger={["click"]}
      >
        <Avatar
          className="mt-2 cursor-pointer"
          size="large"
          style={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
          }}
        >
          {Array.from(loginInfo.user.name)[0].toUpperCase()}
        </Avatar>
      </Dropdown>
      <ChangePassword
        visible={changePassToggle}
        onCancel={() => setChangePassToggle(false)}
      />
    </>
  );
};

export default connect(null, { signOut })(NavProfile);

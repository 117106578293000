import {
  ADD_MENU_IMAGE,
  LIST_MENU_IMAGE,
  MENU_IMAGE_DETAILS,
  MENU_IMAGE_DELETE,
  UPDATE_MENU_IMAGE,
} from "redux/constants/MenuConstants";

export const addMenuImage = (data) => {
  return {
    type: ADD_MENU_IMAGE,
    payload: data,
  };
};

export const listmenuimage = () => {
  return {
    type: LIST_MENU_IMAGE,
  };
};

export const menuimagedetails = (data) => {
  return {
    type: MENU_IMAGE_DETAILS,
    payload: data,
  };
};

export const menuimagedelete = (id) => {
  return {
    type: MENU_IMAGE_DELETE,
    payload: id,
  };
};

export const updatemenuimage = (data) => {
  return {
    type: UPDATE_MENU_IMAGE,
    payload: data,
  };
};

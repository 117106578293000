import {
  ADD_ABOUT,
  ABOUT,
  ABOUT_DETAILS,
  ABOUT_DELETE,
  UPDATE_ABOUT,
} from "redux/constants/AboutConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const AboutReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_ABOUT:
      return {
        ...state,
        redirect: "",
        token: action.token,
        aboutData: action.aboutData,
      };
    case ABOUT:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case ABOUT_DETAILS:
      return {
        ...state,
        redirect: "",
        aboutData: action.payload,
        token: action.token,
      };
    case ABOUT_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };
    case UPDATE_ABOUT:
      return {
        ...state,
        redirect: "",
        aboutData: action.payload,
        token: action.token,
      };
    default:
      return state;
  }
};

export default AboutReducer;

import QRCode from "react-qr-code";

function QRGenerate({ form = {} }) {
  return (
    <div className="text-center">
      <QRCode
        size={100}
        style={{ height: "auto", maxWidth: "100%", width: "150px" }}
        value={JSON.stringify(form)}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
}

export default QRGenerate;

import { Button, ConfigProvider, Form, Input, Radio, Skeleton } from "antd";
import moment from "moment";

export function BookingForm({
  submit,
  loadingDates,
  setSelectedDate,
  times = [],
}) {
  const [form] = Form.useForm();

  function addDays(days) {
    var result = new Date();
    result.setDate(result.getDate() + days);
    return result;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(data) => submit(data, form.resetFields)}
    >
      {/* name */}
      <Form.Item
        name="name"
        required
        label="Name"
        rules={[
          {
            required: true,
            message: "Your name is required",
          },
        ]}
      >
        <Input placeholder="Enter your name" />
      </Form.Item>
      {/* phone */}
      <Form.Item
        name="phone"
        required
        label="Phone Number"
        rules={[
          {
            required: true,
            message: "Your phone is required",
          },
          {
            len: 10,
            message: "Phone number must be 10 digits long",
          },
        ]}
      >
        <Input
          type="number"
          placeholder="Enter phone number"
          onWheel={(e) => e.currentTarget.blur()}
        />
      </Form.Item>
      {/* adults */}
      <Form.Item
        name="od_total"
        required
        label="No. of Adults"
        rules={[
          {
            required: true,
            message: "Customer Name is required",
          },
        ]}
      >
        <Input
          type="number"
          placeholder="Enter number of adults"
          onWheel={(e) => e.currentTarget.blur()}
        />
      </Form.Item>
      {/* date */}
      <Form.Item
        name="reserve_date"
        required
        label="Select Date"
        rules={[
          {
            required: true,
            message: "Booking Date is required",
          },
        ]}
      >
        <Radio.Group
          style={{
            whiteSpace: "nowrap",
            overflow: "auto",
          }}
          onChange={(e) =>
            setSelectedDate(moment(e.target.value).format("YYYY-MM-DD"))
          }
          className="w-100"
          buttonStyle="outline"
        >
          <Radio value={addDays(0).toDateString()}>
            {renderTag(addDays(0).toDateString())}
          </Radio>
          <Radio value={addDays(1).toDateString()}>
            {renderTag(addDays(1).toDateString())}
          </Radio>
          <Radio value={addDays(2).toDateString()}>
            {renderTag(addDays(2).toDateString())}
          </Radio>
          <Radio value={addDays(3).toDateString()}>
            {renderTag(addDays(3).toDateString())}
          </Radio>
          <Radio value={addDays(4).toDateString()}>
            {renderTag(addDays(4).toDateString())}
          </Radio>
        </Radio.Group>
      </Form.Item>
      {/* time */}
      <Form.Item
        name="reserve_checkin_time"
        required
        label="Select Time"
        rules={[
          {
            required: true,
            message: "Time is required",
          },
        ]}
      >
        {loadingDates ? (
          <Skeleton active paragraph={false} />
        ) : (
          <Radio.Group
            className="w-100"
            buttonStyle="solid"
            style={{
              whiteSpace: "nowrap",
              overflow: "auto",
            }}
          >
            {times.map((time) => (
              <Radio key={time} value={time}>
                {renderTag(time)}
              </Radio>
            ))}
          </Radio.Group>
        )}
      </Form.Item>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#00b96b",
            borderRadius: 2,

            // Alias Token
            colorBgContainer: "#f6ffed",
          },
        }}
      >
        <Button className="w-100" type="primary" htmlType="submit">
          RESERVE NOW
        </Button>
      </ConfigProvider>
    </Form>
  );
}

function renderTag(time) {
  return (
    <div
      className="px-5 py-2 border rounded mb-2"
      style={{ border: "#3e79f7" }}
    >
      {time}
    </div>
  );
}

import {
  ADDTABLETYPES,
  TABLETYPES,
  LIST_TABLETYPES,
  TABLETYPES_DETAILS,
  TABLE_TYPE_DELETE,
  UPDATE_TABLE_TYPES,
} from "redux/constants/TableTypeConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const TableTypeReducer = (state = initState, action) => {
  switch (action.type) {
    case ADDTABLETYPES:
      return {
        ...state,
        redirect: "",
        token: action.token,
        tabletypeData: action.tabletypeData,
      };
    case TABLETYPES:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case TABLETYPES_DETAILS: {
      return {
        ...state,
        redirect: "",
        tabletypeData: action.payload,
        token: action.token,
      };
    }

    case TABLE_TYPE_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };

    case UPDATE_TABLE_TYPES:
      return {
        ...state,
        redirect: "",
        token: action.token,
        tabletypeData: action.tabletypeData,
      };
    default:
      return state;
  }
};

export default TableTypeReducer;

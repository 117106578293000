import {
  ADD_TAGS,
  LIST_TAGS,
  TAGS_DETAILS,
  TAGS_DELETE,
  UPDATE_TAGS,
  ASSIGN_TAGS,
  LIST_ASSIGN_TAGS,
  ASSIGN_TAGS_DETAILS,
} from "redux/constants/TagsConstants";

export const addTags = (data) => {
  return {
    type: ADD_TAGS,
    payload: data,
  };
};

export const listTags = () => {
  return {
    type: LIST_TAGS,
  };
};

export const tagsdetails = (data) => {
  return {
    type: TAGS_DETAILS,
    payload: data,
  };
};

export const tagsdelete = (id) => {
  return {
    type: TAGS_DELETE,
    payload: id,
  };
};

export const updateTags = (data) => {
  return {
    type: UPDATE_TAGS,
    payload: data,
  };
};

export const assignTags = (data) => {
  return {
    type: ASSIGN_TAGS,
    payload: data,
  };
};

export const listAssignTags = () => {
  return {
    type: LIST_ASSIGN_TAGS,
  };
};

export const tagsassigndetails = (data) => {
  return {
    type: ASSIGN_TAGS_DETAILS,
    payload: data,
  };
};

import {
  ADD_TAGS,
  TAGS,
  TAGS_DETAILS,
  TAGS_DELETE,
  UPDATE_TAGS,
  ASSIGN_TAGS,
  ASSIGN_TAGS_DETAILS,
} from "redux/constants/TagsConstants";

import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const TagsReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_TAGS:
      return {
        ...state,
        redirect: "",
        token: action.token,
        tagsData: action.tagsData,
      };
    case TAGS:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case TAGS_DETAILS:
      return {
        ...state,
        redirect: "",
        tagsData: action.payload,
        token: action.token,
      };
    case TAGS_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };
    case UPDATE_TAGS:
      return {
        ...state,
        redirect: "",
        tagsData: action.payload,
        token: action.token,
      };
    case ASSIGN_TAGS:
      return {
        ...state,
        redirect: "",
        token: action.token,
        assignTagsData: action.assignTagsData,
      };

    case ASSIGN_TAGS_DETAILS:
      return {
        ...state,
        redirect: "",
        assignTagsData: action.payload,
        token: action.token,
      };
    default:
      return state;
  }
};

export default TagsReducer;

import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, message } from "antd";
import axios from "axios";

const ChangePassword = ({ visible, onCancel }) => {
  const { id } = JSON.parse(localStorage.getItem("user"));
  console.log(id);

  const [form] = Form.useForm();

  async function submitHandler(data) {
    const { oldpassword, password, cpassword } = data;

    if (password !== cpassword) {
      return message.error("Password and Confirm Password don't match");
    }

    await axios
      .post(`/vendor_update/${id}`, { oldpassword, password })
      .then((res) => {
        console.log(res);
        const { status, error } = res.data;
        if (String(status).startsWith("20")) {
          message.success("Password Changed Succesfully");
          form.resetFields();
          onCancel();
        } else {
          message.error(error);
        }
      })
      .catch((err) => {
        console.log("Err", err);
      });
  }

  return (
    <Modal
      footer={null}
      visible={visible}
      title="Change Password"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        onFinish={submitHandler}
      >
        <Form.Item
          name="oldpassword"
          label="Current Password"
          rules={[
            {
              required: true,
              message: "Please input your old password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Current Password"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="New Password"
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item
          name="cpassword"
          label="Confirm Password"
          rules={[
            {
              required: true,
              message: "Please retype your new password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Confirm Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePassword;

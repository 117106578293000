export const FLOOR = "FLOOR";

export const ADDFLOOR = "ADDFLOOR";

export const LIST_FLOOR = "LIST_FLOOR";

export const FLOOR_DETAILS = "FLOOR_DETAILS";

export const FLOOR_DELETE = "FLOOR_DELETE";

export const LIST_TABLE_FLOOR = "LIST_TABLE_FLOOR";

export const TABLE_FLOOR_DETAILS = "TABLE_FLOOR_DETAILS";

export const UPDATE_FLOOR = "UPDATE_FLOOR";

export const SHOW_FLOOR_MESSAGE = "SHOW_FLOOR_MESSAGE";

export const HIDE_FLOOR_MESSAGE = "HIDE_FLOOR_MESSAGE";

export const SHOW_FLOOR_FAILED_MESSAGE = "SHOW_FLOOR_FAILED_MESSAGE";

export const HIDE_FLOOR_FAILED_MESSAGE = "HIDE_FLOOR_FAILED_MESSAGE";

export const SHOW_FLOOR_DELETE_MESSAGE = "SHOW_FLOOR_DELETE_MESSAGE";

export const HIDE_FLOOR_DELETE_MESSAGE = "HIDE_FLOOR_DELETE_MESSAGE";

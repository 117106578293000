import {
  ADDRESERVATION,
  RESERVATION,
  RESERVATION_DETAILS,
  RESERVEBY,
  RESERVE_BY_DETAILS,
  SOURCE,
  SOURCE_BY_DETAILS,
  LIST_WALKIN,
  LIST_WALKIN_DETAILS,
  LIST_WAITING,
  LIST_WAITING_DETAILS,
  LIST_HISTORY_RESERVATION_DETAILS,
  GET_USER_PHONE_DETAILS,
  LIST_DELETED_RESERVATION,
  DELETED_RESERVATION_DETAILS,
} from "redux/constants/ReservationConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const ReservationReducer = (state = initState, action) => {
  switch (action.type) {
    case ADDRESERVATION:
      return {
        ...state,
        redirect: "",
        token: action.token,
        reservationData: action.reservationData,
      };
    case RESERVATION:
      return {
        ...state,
        redirect: "",
        token: action.token,
        reservationData: action.payload,
      };
    case RESERVATION_DETAILS: {
      return {
        ...state,
        redirect: "",
        reservationData: action.payload,
        token: action.token,
      };
    }

    case RESERVEBY: {
      return {
        ...state,
        redirect: "",
        // token: action.token,
        reservebyData: action.payload,
      };
    }
    case RESERVE_BY_DETAILS: {
      return {
        ...state,
        redirect: "",
        reservebyData: action.payload,
        // token: action.token,
      };
    }

    case SOURCE: {
      return {
        ...state,
        redirect: "",
        // token: action.token,
        sourcebyData: action.payload,
      };
    }

    case SOURCE_BY_DETAILS: {
      return {
        ...state,
        redirect: "",
        sourcebyData: action.payload,
        // token: action.token,
      };
    }

    case LIST_WALKIN: {
      return {
        ...state,
        redirect: "",
        // token: action.token,
        walkinData: action.payload,
      };
    }
    case LIST_WALKIN_DETAILS: {
      return {
        ...state,
        redirect: "",
        walkinData: action.payload,
        // token: action.token,
      };
    }

    case LIST_WAITING: {
      return {
        ...state,
        redirect: "",
        // token: action.token,
        waitingData: action.payload,
      };
    }
    case LIST_WAITING_DETAILS: {
      return {
        ...state,
        redirect: "",
        waitingData: action.payload,
        // token: action.token,
      };
    }
    case LIST_HISTORY_RESERVATION_DETAILS: {
      return {
        ...state,
        redirect: "",
        historyReservation: action.payload,
        // token: action.token,
      };
    }

    case GET_USER_PHONE_DETAILS: {
      return {
        ...state,
        redirect: "",
        name: action.payload,
        // token: action.token,
      };
    }

    case LIST_DELETED_RESERVATION: {
      return {
        ...state,
        redirect: "",
        // token: action.token,
        deletedData: action.payload,
      };
    }
    case DELETED_RESERVATION_DETAILS: {
      return {
        ...state,
        redirect: "",
        deletedData: action.payload,
        // token: action.token,
      };
    }
    default:
      return state;
  }
};

export default ReservationReducer;

import {
  ADD_FEATURES,
  FEATURES,
  FEATURES_DETAILS,
  UPDATE_FEATURES,
  FEATURES_DELETE,
  ASSIGN_FEATURES,
  ASSIGN_FEATURES_DETAILS,
  SHOW_FEATURE_MESSAGE,
  HIDE_FEATURE_MESSAGE,
} from "redux/constants/FeaturesConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const FeaturesReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_FEATURES:
      return {
        ...state,
        redirect: "",
        token: action.token,
        featuresData: action.featuresData,
      };
    case FEATURES:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case FEATURES_DETAILS: {
      return {
        ...state,
        redirect: "",
        featuresData: action.payload,
        token: action.token,
      };
    }

    case FEATURES_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };

    case UPDATE_FEATURES:
      return {
        ...state,
        redirect: "",
        featuresData: action.payload,
        token: action.token,
      };
    case ASSIGN_FEATURES:
      return {
        ...state,
        redirect: "",
        token: action.token,
        assignFeaturesData: action.assignFeaturesData,
      };

    case ASSIGN_FEATURES_DETAILS:
      return {
        ...state,
        redirect: "",
        assignFeaturesData: action.payload,
        token: action.token,
      };

    case SHOW_FEATURE_MESSAGE:
      return {
        ...state,
        message: action.message,
        showFeatureSuccessMessage: true,
        loading: false,
      };

    case HIDE_FEATURE_MESSAGE:
      return {
        ...state,
        message: "",
        showFeatureSuccessMessage: false,
      };
    default:
      return state;
  }
};

export default FeaturesReducer;
